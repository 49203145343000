import React from "react";
import { graphql } from 'gatsby'
import Slider from "react-slick";
// import Img from "gatsby-image";
import AOS from 'aos';
import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default function Projects({data}) {
  const technology = data.wordpressPage.acf.technology;
  const marketing = data.wordpressPage.acf.marketing;
  const page = data.wordpressPage;
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }

  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    lazyLoad: true,
    speed: 500,
    centerPadding: '20%',
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  return (
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-5 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <div className="header-text-container">
                  <div data-aos="fade-up" className="vertical-center" dangerouslySetInnerHTML={{__html: page.content}} />
                </div>
              </div>
              <div className="col-md-6">
                <img
                  data-src={page.featured_media && page.featured_media.source_url}
                  className="header-image lazyload"
                  alt="Our Projects | Digital Agency Portfolio &amp; Case Studies | Portfolio | Alkye"
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid primary-color-bg ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-5 pb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h2 data-aos="fade-up" className="aos-init aos-animate">Technology.</h2>
                    {/* <p data-aos="fade-up" className="pt-3 text-primary-color"></p> */}

                    {/* <div className="live__scroll"> */}
                      
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pattern-row text-left">
          {technology.map((tech, index) => (
            <div key={index}
              className="col-md-12 col-lg-12 mb-2 px-0"
            >
              {/* live__scroll--box */}

              {/* <div data-nane
                className="our-work-slider-div"
                style={{
                  backgroundImage: 'url(' + tech.image.source_url + ')',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div> */}
              {tech.gallery.length > 1 ? (
                <Slider className="work__slider home-slick-nav" {...settings}>
                  {tech.gallery && tech.gallery.map((gall, index) => (
                    <div className="work__slider_item">
                      {(() => {
                        if(gall.slice(-3) === 'mp4'){
                          return(
                            <div className="work__slider_image" key={index}>
                              <video
                                className="lazyload"
                                controls
                                muted
                                webkit-playsInline={true}
                                playsInline={true}
                                data-src={gall}
                                loop
                                autoPlay
                                src={gall}
                                // disablepictureinpicture 
                                // controlslist="nodownload"
                                poster="https://alkye.co/wp-content/uploads/2021/06/stylefix-video-poster.png"
                              >
                                {/* <source
                                  src={gall}
                                  type="video/mp4"
                                />
                                <track default="" kind="captions" srcLang="en" /> */}
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )
                        }else{
                          return(
                            <div className="work__slider_image" key={index} style={{ backgroundImage: `url(${gall})` }}></div>
                          )
                        }
                      })()}
                    </div>
                  ))}
                </Slider>
              ) : (
                tech.gallery && tech.gallery.map((gall, index) => {
                  if(gall.slice(-3) === 'mp4'){
                    return(
                      <div className="our-work-slider-div mb-3 our-work-slider-div-single" key={index}>
                        <video
                          className="lazyload"
                          controls
                          muted
                          webkit-playsInline={true}
                          playsInline={true}
                          data-src={gall}
                          loop
                          autoPlay
                          src={gall}
                          // disablepictureinpicture={true}
                          // controlslist="nodownload"
                          poster="https://alkye.co/wp-content/uploads/2021/06/stylefix-video-poster.png"
                        >
                          {/* <source
                            src={gall}
                            type="video/mp4"
                          />
                          <track default="" kind="captions" srcLang="en" /> */}
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )
                  }else{
                    return(
                      <div
                        className="our-work-slider-div mb-3 our-work-slider-div-single" key={index}
                        style={{
                          backgroundImage: 'url(' + gall + ')',
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    )
                  }
                })
              )}
              <div className="text-center work__slider_content px-3 pb-0">
                <h2>{tech.service_description}</h2>
                <h3>{tech.client_name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-5">
            <div className="row">
              <div className="col-md-12">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h2 data-aos="fade-up" className="aos-init aos-animate">Marketing.</h2>
                    <p data-aos="fade-up" className="pt-3 text-primary-color"> </p>

                    <div className="live__scroll section-dark main-container-padding-t-5 main-container-padding-b-5">
                    <div className="row text-left">
                        {marketing.map((market, index) => (
                          <div key={index}
                            className="col-md-12 col-lg-8 live__scroll--box"
                            id="slide-1"
                          >
                            <div
                              className="our-work-slider-div"
                              style={{
                                backgroundImage: 'url(' + market.image_marketing.source_url + ')',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <h5>{market.client_name}</h5>
                            <p className="smaller-text text-primary-color">
                              {market.service_description}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  )
}

function SlideNextArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="home-slick-next slick-arrow">
      <i className="la la-arrow-right"></i>
    </button>
  );
}

function SlidePrevArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="home-slick-prev slick-arrow">
      <i className="la la-arrow-left"></i>
    </button>
  );
}



export const query = graphql`
{
  wordpressPage(slug: {eq: "our-projects"}) {
    content
    featured_media {
      source_url
    }
    acf {
      technology {
        client_name
        service_description
        gallery
      }
      marketing {
        image_marketing {
          source_url
        }
        client_name
        service_description
      }
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`